import React, { useContext,useState, useEffect, useRef, } from 'react';
import './PreviewProject.css';
import { LanguageContext} from './LanguageContext';

const PreviewProject = ({infos}) => {
    const { language, changeLanguage } = useContext(LanguageContext);
    const [years,setYears] = useState("2024")
    
    useEffect(() => {
        const newYears=new Date(infos.date).getFullYear().toString()
        setYears(newYears);
    })

    return (
        <a href={'/project/'+[infos.id]} className='Preview-Project_Main'>
            <div className='container'>
                {infos.media && <img className="img-project" src={'https://julesherve.com:4000/image/' + infos.media[0].path} alt={infos.name} />}
            </div>
            {language==="fr" && <span>{infos.name+" - "+years}</span>}
            {language==="en" && <span>{infos.name_en+" - "+years}</span>}
        </a>
    );
}

export default PreviewProject;
