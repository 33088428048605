import React, { useState, useEffect } from 'react';
import { api } from '../api';
import './DeletePage.css'

const DeletePage = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Appeler la route pour obtenir la liste des projets
    api.get('/project/All')
      .then(response => setProjects(response.data))
      .catch(error => console.error('Erreur lors de la récupération des projets:', error));
  }, []);

  const handleDelete = (projectId) => {
    // Appeler la route pour supprimer le projet de la base de données
    api.delete(`/deleteProject/${projectId}`)
      .then(response => {
        // Mettre à jour la liste des projets après la suppression réussie
        setProjects(projects.filter(project => project.id !== projectId));
      })
      .catch(error => console.error('Erreur lors de la suppression du projet:', error));
  };

  return (
    <div className='Main-deletePage'>
      <h2>Liste des Projets</h2>
      <ul className='Ul-deletePage'>
        {projects.map(project => (
          <li key={project._id} className='List-deletePage'>
            {project.name}
            <img className='Img-deletePage' src={'https://julesherve.com:4000/image/' + project.media[0].path}/>
            <button onClick={() => handleDelete(project.id)}>Supprimer</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DeletePage;
